@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #eeeeee;
}

.navbar--wrapper {
  padding: 50px 0 30px;
  box-sizing: border-box;
}
.navbar--list ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  margin: 0 0;
}

.navbar--list ul li a {
  font-weight: bold;;
  text-transform: uppercase;
  text-decoration: none;
  color: #111;;
}

.navbar--link-active {
  color: #e74b3b !important;
}
.subnav--wrapper {
  background-color: #333333;
  color: white;
}
.subnav--list {
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
}
.subnav--list-category ul {
  list-style: none;
  display: flex;
  flex-direction: row;
}
.subnav--list-category ul li {
  border-right: 1px solid #676565;
  padding: 10px 0;;
}
.subnav--list-category ul li a {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 15px;
  text-decoration: none;
  font-weight: bold;
}

.subnav--list ul, p {
  margin: 0 0;
}

.category--active {
  background-color: #e74b3b;
  padding: 5px;
}

.subnav--search {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #e74b3b;
}
.content--thumbnail {
  position: relative;
  max-height: 367px;
  overflow: hidden;
}
.content--thumbnail img {
  width: 100%;
  max-height: 367px;
  object-fit: cover;
}

.highlight--content {
  cursor: pointer;
  position: relative;
}

.highlight--content:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.highlight--content:hover::before {
  font-family: "FontAwesome";
  font-size: 32px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\f0c1";
  background-color: rgba(231, 75, 59, 0.8);
  z-index: 12;
}

.content--info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 5px;
  color: #fff;
  padding: 0 5px;
  z-index: 5;
}
.content--info h1 {
  font-size: 32px;
  font-weight: bold !important;
}
.content--info p {
  margin: 0 0;
  font-size: 12px;
  font-weight: 400;
}

.title--small {
  font-size: 18px !important;
}

.highlight--small {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.events--title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 25px 0;
  border: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #e74b3a;
}
.events--title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 16px 20px 12px 20px;
  background-color: #e74b3a;
  margin: 0 0;
  letter-spacing: 1px;
}
.events--sub-title {
  cursor: pointer;
  display: inline-block;
  line-height: 30px;
  position: relative;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  height: 30px;
  padding: 0 15px;
  top: 19px;
  background-color: #333;
  margin: 0 0;
  letter-spacing: 1px;
}

.events--content h4 {
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  text-transform: uppercase;
}
.events--content img {
  width: 100%;
}
.events--content p {
  padding: 10px 0;
  margin: 0 0;
  font-size: 12px;
}
.featured-player--title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 25px 0;
  border: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #e74b3a;
}
.featured-player--title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 16px 20px 12px 20px;
  background-color: #e74b3a;
  margin: 0 0;
  letter-spacing: 1px;
}
.featured-player--sub-title {
  cursor: pointer;
  display: inline-block;
  line-height: 30px;
  position: relative;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  height: 30px;
  padding: 0 15px;
  top: 19px;
  background-color: #333;
  margin: 0 0;
  letter-spacing: 1px;
}

.featured-player--content h4 {
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  text-transform: uppercase;
}
.featured-player--content img {
  width: 100%;
  max-height: 170px;
  object-fit: cover;
}
.featured-player--content p {
  padding: 10px 0;
  margin: 0 0;
  font-size: 12px;
}

.news--title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 25px 0;
  border: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #e74b3a;
}
.news--title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 16px 20px 12px 20px;
  background-color: #e74b3a;
  margin: 0 0;
  letter-spacing: 1px;
}
.news--sub-title {
  cursor: pointer;
  display: inline-block;
  line-height: 30px;
  position: relative;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  height: 30px;
  padding: 0 15px;
  top: 19px;
  background-color: #333;
  margin: 0 0;
  letter-spacing: 1px;
}

.news--content h4 {
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  text-transform: uppercase;
}
.news--content img {
  width: 100%;
}
.news--content p {
  padding: 10px 0;
  margin: 0 0;
  font-size: 12px;
}
.contributor--title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 25px 0;
  border: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #e74b3a;
}
.contributor--title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 16px 20px 12px 20px;
  background-color: #e74b3a;
  margin: 0 0;
  letter-spacing: 1px;
}
.contributor--sub-title {
  cursor: pointer;
  display: inline-block;
  line-height: 30px;
  position: relative;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  height: 30px;
  padding: 0 15px;
  top: 19px;
  background-color: #333;
  margin: 0 0;
  letter-spacing: 1px;
}

.contributor--content h4 {
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  text-transform: uppercase;
}
.contributor--content img {
  width: 100%;
}
.contributor--content p {
  padding: 10px 0;
  margin: 0 0;
  font-size: 12px;
}
.contributor--list-content {
  padding: 25px 30px;
  margin: 0 0 10px;
  color: #fff;
}
.contributor--list-content h6 {
  margin: 0 0;
  font-size: 14px;
  font-weight: bold;
}
.article {
  background-color: #3498db;
}
.startup {
  background-color: #2fcc71;
}
.event {
  background-color: #f49c12;
}
.subs--title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 25px 0;
  border: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #e74b3a;
}
.subs--title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 16px 20px 12px 20px;
  background-color: #e74b3a;
  margin: 0 0;
  letter-spacing: 1px;
}
.subs--sub-title {
  cursor: pointer;
  display: inline-block;
  line-height: 30px;
  position: relative;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  height: 30px;
  padding: 0 15px;
  top: 19px;
  background-color: #333;
  margin: 0 0;
  letter-spacing: 1px;
}

.subs--content h4 {
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  text-transform: uppercase;
}
.subs--content img {
  width: 100%;
}
.subs--content p {
  padding: 10px 0;
  margin: 0 0;
  font-size: 12px;
}

.subs--input-wrapper {
  width: 100%;
  padding: 30px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.subs--input-wrapper input {
  outline: none;
  border: none;
}
.subs--input-wrapper i {
  cursor: pointer;
}
.footer--wrapper {
  position: relative;
  bottom: 0;
  padding: 50px 0;
  background-color: #333333;
}
.footer--content {
  color: #fff;
}
.footer--content h5 {
  font-size: 24px;
  font-weight: bold;;
  text-transform: uppercase;
}
.footer--content p, a {
  color: #8d8d8d;
  margin: 0 0;
  font-size: 12px;
}
.footer--content a {
  color: #e74b3a;
}
.footer--content ul {
  list-style: none;
}
.footer--content ul li > a{
  color: #8d8d8d;
  font-size: 12px;
  padding: 10px 0;
}
.footer--content ul li a > span {
  margin-left: 10px;
}
.footer--copyright {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: rgb(182, 180, 180);
  background-color: #111;
  padding: 18px;
  font-size: 12px;
  margin-top: 50px;
}
