.subs--title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 25px 0;
  border: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #e74b3a;
}
.subs--title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 16px 20px 12px 20px;
  background-color: #e74b3a;
  margin: 0 0;
  letter-spacing: 1px;
}
.subs--sub-title {
  cursor: pointer;
  display: inline-block;
  line-height: 30px;
  position: relative;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  height: 30px;
  padding: 0 15px;
  top: 19px;
  background-color: #333;
  margin: 0 0;
  letter-spacing: 1px;
}

.subs--content h4 {
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  text-transform: uppercase;
}
.subs--content img {
  width: 100%;
}
.subs--content p {
  padding: 10px 0;
  margin: 0 0;
  font-size: 12px;
}

.subs--input-wrapper {
  width: 100%;
  padding: 30px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.subs--input-wrapper input {
  outline: none;
  border: none;
}
.subs--input-wrapper i {
  cursor: pointer;
}