.content--thumbnail {
  position: relative;
  max-height: 367px;
  overflow: hidden;
}
.content--thumbnail img {
  width: 100%;
  max-height: 367px;
  object-fit: cover;
}

.highlight--content {
  cursor: pointer;
  position: relative;
}

.highlight--content:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.highlight--content:hover::before {
  font-family: "FontAwesome";
  font-size: 32px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\f0c1";
  background-color: rgba(231, 75, 59, 0.8);
  z-index: 12;
}

.content--info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 5px;
  color: #fff;
  padding: 0 5px;
  z-index: 5;
}
.content--info h1 {
  font-size: 32px;
  font-weight: bold !important;
}
.content--info p {
  margin: 0 0;
  font-size: 12px;
  font-weight: 400;
}

.title--small {
  font-size: 18px !important;
}

.highlight--small {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
