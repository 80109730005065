.footer--wrapper {
  position: relative;
  bottom: 0;
  padding: 50px 0;
  background-color: #333333;
}
.footer--content {
  color: #fff;
}
.footer--content h5 {
  font-size: 24px;
  font-weight: bold;;
  text-transform: uppercase;
}
.footer--content p, a {
  color: #8d8d8d;
  margin: 0 0;
  font-size: 12px;
}
.footer--content a {
  color: #e74b3a;
}
.footer--content ul {
  list-style: none;
}
.footer--content ul li > a{
  color: #8d8d8d;
  font-size: 12px;
  padding: 10px 0;
}
.footer--content ul li a > span {
  margin-left: 10px;
}
.footer--copyright {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: rgb(182, 180, 180);
  background-color: #111;
  padding: 18px;
  font-size: 12px;
  margin-top: 50px;
}