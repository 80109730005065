.navbar--wrapper {
  padding: 50px 0 30px;
  box-sizing: border-box;
}
.navbar--list ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  margin: 0 0;
}

.navbar--list ul li a {
  font-weight: bold;;
  text-transform: uppercase;
  text-decoration: none;
  color: #111;;
}

.navbar--link-active {
  color: #e74b3b !important;
}